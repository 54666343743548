body {
  background-image: url(Extranet Entry_RIL1350_Seafarers_S130_EXT_NewHero_Final.jpg);
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: right;
}

.mainTheme {
  background-color: transparent;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.border {
  border: 10px transparent;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.white-text {
  color: white;
}

.black-background {
  background-color: black;
}

.full-height {
  height: 100vh;
}

.flex-container-row {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: normal;
  align-content: stretch;
}

.flex-items-row:nth-child(1) {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.flex-items-row:nth-child(2) {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.flex-container-col {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: normal;
  align-content: stretch;
}

@media (orientation: portrait) {
  .flex-container-col {
    flex-direction: column;
  }

  body {
      background-image: none;
    background: black;
  }
}

.flex-items-col:nth-child(1) {
  display: block;
  flex-grow: 4;
  flex-shrink: 2;
  align-self: auto;
  order: 0;
}

.flex-items-col:nth-child(2) {
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 300px;
  align-self: center;
  order: 0;
  margin: auto;
  
}