
.pageText {
    color: white;
}

.pageDescription {
    padding-left: 100px;
    padding-right: 100px;
}

.pageQuote {
    font-style: italic;
    margin-right: 50px;
    padding-top: 110px;
    font-size: x-large;
}